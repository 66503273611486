<template>
  <v-container fluid>
    <SharedToolbar />
    <v-card>
      <v-card-text>
        <v-alert outlined type="warning"
          >管理者権限がありません。管理者権限のあるIDでログインしてください。</v-alert
        >
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn text @click="onSignOut">OK</v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import { mapMutations } from 'vuex'
import { firebase } from '@/services/firebase'
import SharedToolbar from '../components/SharedToolbar'

export default {
  name: 'invalidAdmin',
  components: {
    SharedToolbar
  },
  methods: {
    ...mapMutations(['setUId', 'setAdmin', 'setSignInDone']),
    async onSignOut () {
      this.setSignInDone(false)
      firebase.auth().signOut()
      this.$router.replace('/')
    }
  }
}
</script>
